export const header: Props = {
  title: 'DTBIM数字主线引擎',
  logo: '/home/logo.png',
  navigation: [
    {
      href: '/portal/home',
      name: "首页"
    },
    {
      // href: '#',
      href: '/portal/home/feature',
      name: "功能展示"
    },
    {
      // href: '#',
      href: '/portal/home/example',
      name: "示例场景"
    },
    {
      // href: '/portal/home/standard/search',
      href: '#',
      name: "数据标准",
      subMenu: [
        { href: '/portal/home/standard/search', name: "标准检索" },
        { href: '/portal/home/standard/browse', name: "标准浏览" },
      ],
    },
    {
      // href: '/portal/home/changelog',
      href: '#',
      name: "更新日志",
    },
    {
      // href: '/portal/home/develop',
      href: '#',
      name: "开发者中心",
      subMenu: [
        { href: '/portal/home/docs/client', name: "客户端SDK文档" },
        { href: '/portal/home/docs/server', name: "服务端API文档" },
      ],
    }
  ],
  functional: [
    {
      href: '/login',
      name: '登录',
    },
    {
      // href: '/register',
      href: '#',
      name: '注册',
    }
  ],
  // bg: 'linear-gradient(#000000, rgb(21 28 36 / 1))',
}

interface Props {
  title: string;
  bg?: string;
  logo: string;
  functional?: Functional[];
  navigation?: NavigationItem[];
}

interface NavigationItem {
  name: string;
  href: string;
  subMenu?: SubMenuItem[];
}

interface SubMenuItem {
  name: string;
  href: string;
}

interface Functional {
  href: string;
  name: string;
}